<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="8"
      >
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Create New community
            </div>

            <div class="subtitle-1 font-weight-light">
              Complete your profile
            </div>
          </template>

          <v-form>
            <template>
              <validation-observer ref="observer">
                <form>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Name"
                  >
                    <v-text-field
                      v-model="name"
                      :error-messages="errors"
                      label="Name"
                      required
                      prepend-icon="mdi-pencil-plus"
                    />
                  </validation-provider>
                  <validation-provider name="Status">
                    <v-select
                      v-model="status"
                      :items="status_items"
                      label="Pilih Status"
                      prepend-icon="mdi-format-list-checks"
                    />
                  </validation-provider>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Prefix"
                  >
                    <v-text-field
                      v-model="prefix"
                      :error-messages="errors"
                      label="Prefix"
                      required
                      prepend-icon="mdi-pencil-plus"
                    />
                  </validation-provider>

                  <v-btn
                    class="mr-4"
                    type="submit"
                    @click.prevent="add"
                  >
                    submit
                  </v-btn>
                </form>
              </validation-observer>
            </template>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = 'https://nc.notiva.net'
  axios.defaults.headers.common.Authorization =
    'Bearer ' + localStorage.getItem('access_token')
  export default {
    name: 'CreateCommunity',
    data () {
      return {
        name: '',
        status_items: ['active', 'inactive'],
        prefix: '',
        status: null,
      }
    },
    methods: {
      add () {
        axios
          .post('/v1/community', {
            name: this.name,
            status: this.status,
            prefix: this.prefix,
          })
          .then(response => {
            if (response.data.meta.status) {
              this.$router.push({ path: '/community' })
              this.$toast.success('Berhasil Di Buat', {
                type: 'success',
                position: 'top-right',
                duration: 3000,
                dismissible: true,
              })
            } else {
              console.log(response.data.meta.message)
            }
          })
      },
    },
  }
</script>

<style></style>
